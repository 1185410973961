<template>
  <div>
    <h3>Results per Outcome</h3>
    <div class="dropdown-container">
      <select
        id="country-dropdown"
        v-model="currentTypeOfEvidence"
        class="neumorphic-select"
      >
        <option value="" disabled>Select a type</option>
        <option v-for="type in typeOfEvidence" :key="type" :value="type">
          {{ type }}
        </option>
      </select>

      <q-btn
        @click="selectOtherResults"
        class="add-new-btn neumorphic-btn"
        label="Select other Results from this Output"
        color="primary"
      />
    </div>

    <div>
      <label for="studyName">Name of Study:</label>
      <input type="text" v-model="studyName" placeholder="Enter study name" />
    </div>

    <div>
      <label for="relativeEffectSize"
        >Value for Relative Effect Size (Point Estimate):</label
      >
      <input
        type="number"
        v-model="relativeEffectSize"
        placeholder="Enter point estimate"
      />
    </div>

    <div>
      <label for="pValue">Significance of Point Estimate (p-value):</label>
      <input
        type="number"
        step="0.001"
        v-model="pValue"
        placeholder="Enter p-value"
      />
    </div>

    <div>
      <label for="confidenceInterval"
        >95% Confidence Interval of the Point Estimate:</label
      >
      <input
        type="text"
        v-model="confidenceInterval"
        placeholder="Enter CI (e.g., 1.5 to 2.5)"
      />
    </div>

    <div>
      <label for="treatmentEffectIntervention"
        >Absolute Value for Treatment Effect of Our Asset (Intervention):</label
      >
      <input
        type="number"
        v-model="treatmentEffectIntervention"
        placeholder="Enter treatment effect"
      />
    </div>

    <div>
      <label for="treatmentEffectComparator"
        >Absolute Value for Treatment Effect of Comparator (Control
        Group):</label
      >
      <input
        type="number"
        v-model="treatmentEffectComparator"
        placeholder="Enter treatment effect"
      />
    </div>

    <!-- Data Finality -->
    <div>
      <label>Are the data final or interim?</label>
      <div>
        <label>
          <input type="radio" v-model="dataFinality" :value="true" /> Yes, final
        </label>
        <label>
          <input type="radio" v-model="dataFinality" :value="false" /> No, only
          interim results
        </label>
      </div>
    </div>

    <q-btn
      @click="showConfirmAddResult"
      class="add-new-btn neumorphic-btn"
      label="Submit Results"
      color="primary"
    />
    <q-btn
      @click="submitResults"
      class="add-new-btn neumorphic-btn"
      label="Upload Study"
      color="primary"
    />

    <!-- Modal for confirming to add new result -->
    <ConfirmAddResult
      v-if="isConfirmAddResultOpen"
      @close="closeConfirmAddResult"
      @confirm="handleConfirmAddResult"
    />

    <SelectOtherResults
      v-if="isDummyComponentOpen"
      @close="closeDummyComponent"
    />
  </div>
</template>

<script>
import EventBus from '@/eventBus.js'
import axios from 'axios'
import ConfirmAddResult from './ConfirmAddResult.vue'
import SelectOtherResults from './SelectOtherResults.vue'

export default {
  name: 'ResultsForm',
  components: {
    SelectOtherResults,
    ConfirmAddResult,
  },
  data() {
    return {
      resultId: -1,
      studyName: '',
      relativeEffectSize: null,
      pValue: null,
      confidenceInterval: '',
      treatmentEffectIntervention: null,
      treatmentEffectComparator: null,
      currentTypeOfEvidence: '',
      dataFinality: true,
      isDummyComponentOpen: false,
      isConfirmAddResultOpen: false,
      typeOfEvidence: [
        'Randomized controlled trial (RCT)',
        'Subgroup analysis of RCT data',
        'Bayesian network meta-analysis (NMA)',
        'Standard NMA (e.g. Bucher’s adjusted indirect comparison)',
        'Patient-level adjustment to external controls (e.g.: by using propensity scores)',
        'Population-level adjustment methods: (e.g.: MAIC or STC)',
        'Naïve/unadjusted comparison',
        'Other (please specify)',
      ],
    }
  },
  methods: {
    async getSelectedResult() {
      try {
        const response = await axios.get(
          'jca/selectedOutcomeResultForConsolidatedPico',
        )
        const data = response.data
        // Populate the form fields with data from the backend
        this.resultId = data.id || -1
        this.studyName = data.studyName || ''
        this.relativeEffectSize = data.relativeEffectSize || null
        this.pValue = data.pValue || null
        this.confidenceInterval = data.confidenceInterval || ''
        this.treatmentEffectIntervention =
          data.treatmentEffectIntervention || null
        this.treatmentEffectComparator = data.treatmentEffectComparator || null
        this.currentTypeOfEvidence = data.currentTypeOfEvidence || ''
        this.dataFinality = data.dataFinality === true // Ensure boolean value
      } catch (error) {
        console.error('Error fetching selected result:', error)
      }
    },
    async resetInputs() {
      this.resultId = -1
      this.studyName = ''
      this.relativeEffectSize = null
      this.pValue = null
      this.confidenceInterval = ''
      this.treatmentEffectIntervention = null
      this.treatmentEffectComparator = null
      this.currentTypeOfEvidence = ''
      this.dataFinality = true // Reset to default
      await axios.post(`/jca/resetSelectedOutcomeResult`)
    },
    openDummyComponent() {
      this.isDummyComponentOpen = true
    },
    async closeDummyComponent() {
      this.isDummyComponentOpen = false
      await this.getSelectedResult() // Fetch and populate the data when closing
    },
    showConfirmAddResult() {
      if (!this.validateInputs()) {
        return // Stop submission if validation fails
      }
      this.isConfirmAddResultOpen = true // Open the confirmation modal
    },
    async closeConfirmAddResult() {
      EventBus.emit('submit-study-results-clicked')
      await axios.post(`/jca/resetSelectedOutcomeResult`)
      this.isConfirmAddResultOpen = false // Close the confirmation modal
    },
    async handleConfirmAddResult() {
      EventBus.emit('addStudyClicked')
      await axios.post(`/jca/resetSelectedOutcomeResult`)
      this.isConfirmAddResultOpen = false
      // Prepare the data to be sent to the backend
      const results = {
        id: this.resultId,
        studyName: this.studyName,
        relativeEffectSize: this.relativeEffectSize,
        pValue: this.pValue,
        confidenceInterval: this.confidenceInterval,
        treatmentEffectIntervention: this.treatmentEffectIntervention,
        treatmentEffectComparator: this.treatmentEffectComparator,
        currentTypeOfEvidence: this.currentTypeOfEvidence,
        dataFinality: this.dataFinality,
      }

      try {
        await axios.post(`jca/saveOutcomeResults/`, results)
      } catch (error) {
        console.error('Error saving results:', error)
      }
    },
    validateInputs() {
      if (
        !this.currentTypeOfEvidence ||
        !this.studyName ||
        this.relativeEffectSize === null
      ) {
        alert(
          'Please fill in the recommended fields: Type of Evidence, Name of Study, and Relative Effect Size.',
        )
        return false
      }
      return true
    },
    submitResults() {
      if (!this.validateInputs()) {
        return // Stop submission if validation fails
      }

      // Handle form submission logic here
      const results = {
        studyName: this.studyName,
        relativeEffectSize: this.relativeEffectSize,
        pValue: this.pValue,
        confidenceInterval: this.confidenceInterval,
        treatmentEffectIntervention: this.treatmentEffectIntervention,
        treatmentEffectComparator: this.treatmentEffectComparator,
      }
      console.log('Submitted Results:', results)
    },
    selectOtherResults() {
      this.isDummyComponentOpen = true
    },
    async fetchSelectedOutcomeForConsolidatedPico() {
      await this.resetInputs() // Reset inputs when outcome is selected
      // Additional logic to fetch data can be added here if necessary
    },
  },
  async mounted() {
    await this.getSelectedResult()
    EventBus.on(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
  },
  beforeUnmount() {
    EventBus.off(
      'outcome-selected',
      this.fetchSelectedOutcomeForConsolidatedPico,
    )
  },
}
</script>

<style scoped>
div {
  margin-bottom: 15px;
}
input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}
label {
  display: block;
  margin-bottom: 5px;
}
button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
.dropdown-container {
  display: flex;
  align-items: center;
}
.neumorphic-select {
  flex: 1;
  margin-right: 10px;
}
</style>
