<template>
  <q-table
    :rows="rows"
    :columns="columns"
    :rows-per-page-options="[0]"
    no-data-label="There is no data available."
    :wrap-cells="true"
    :flat="true"
    :bordered="true"
    hide-bottom
    :style="{ height: tableHeight }"
  >
    <template v-slot:top>
      <div class="table-top-container">
        <div class="table-title">{{ tableTitle }}</div>
        <div class="add-study-container">
          <slot name="title-action"></slot>
        </div>
      </div>
    </template>
    <template v-slot:header-cell="props">
      <q-th>
        <div class="header-cell-content">
          <span>{{ props.col.label }}</span>
          <q-icon
            v-if="props.col.headerOptions"
            name="info"
            size="xs"
            class="info-icon"
          >
            <q-tooltip>{{ props.col.headerOptions }}</q-tooltip>
          </q-icon>
        </div>
      </q-th>
    </template>
    <template v-slot:body-cell="props">
      <q-td>
        <BodyCell
          :content="props.row && props.row[props.col.field]"
          :type="rowTypes[props.rowIndex][props.col.field]"
          :isMandatory="isMandatory[props.rowIndex][props.col.field]"
          :options="props.col && props.col.options"
          :rowIndex="props.rowIndex"
          :columnIndex="getColumnIndex(props.col.field)"
          :columnTitle="props.col.label"
          @option-selected="handleOptionSelected"
          @link-clicked="handleLinkClicked"
          @update-value="updateValue"
          @delete-clicked="handleDeleteClicked"
          @update-options="handleOptionsUpdate"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import BodyCell from '@/core_controls/GipamCells/GipamTableCellView.vue'

export default {
  name: 'GipamTable',
  components: {
    BodyCell,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    rowTypes: {
      type: Object,
      required: true,
    },
    isMandatory: {
      type: Object,
    },
    tableTitle: {
      type: String,
      default: '',
    },
    rowsPerPage: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateTableHeight)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateTableHeight)
  },
  computed: {
    tableHeight() {
      const viewportHeight = window.innerHeight
      const desiredHeight = viewportHeight * 0.8 // Adjust the desired height as needed
      return `${desiredHeight}px`
    },
  },
  methods: {
    updateTableHeight() {
      this.$forceUpdate()
    },
    getColumnIndex(field) {
      return this.columns.findIndex((column) => column.field === field)
    },
    handleOptionSelected(selectedOption, rowIndex, columnIndex) {
      this.$emit('option-selected', selectedOption, rowIndex, columnIndex)
    },
    handleLinkClicked(content, rowIndex, columnIndex) {
      this.$emit('link-clicked', content, rowIndex, columnIndex)
    },
    handleDeleteClicked(content, rowIndex) {
      this.$emit('delete-clicked', content, rowIndex)
    },
    handleOptionsUpdate(value, rowIndex, columnIndex) {
      this.$emit('update-options', value, rowIndex, columnIndex)
    },
    updateValue(value, rowIndex, columnIndex) {
      this.$emit('updateValue', value, rowIndex, columnIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .table-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }

  .add-study-container {
    display: flex;
    align-items: center;
  }
}

::v-deep .q-table {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Add this rule to enable horizontal scrolling */

  thead {
    tr {
      th {
        position: sticky; /* Make the header sticky */
        top: 0; /* Stick the header to the top of the table */
        background-color: #f5f5f5;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 12px;
        border-bottom: 1px solid #ddd;
        z-index: 1; /* Ensure the header is above the table body */
      }
    }
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tbody tr:hover {
    background-color: #f0f0f0;
  }

  .q-td {
    padding: 12px;
    border-right: 1px solid #ddd;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
